@import "fonts";

body{
  // background-color: rgb(251, 251, 251);
  font-family: $mjh;
}

.vt-header{
  position: relative;
  background-size: cover;
  padding: 0;
  height:267px;
  z-index: 2;

  // @media screen and (max-width: 1200px) {
  //   height:267px *0.828;
  // }
  //
  // @media screen and (max-width: 994px) {
  //   height:267px *0.64;
  // }

  @media screen and (max-width: 768px) {
    height:267px *0.64;
  }

  .vt-logo{
    padding: 0;
  }

  .logo{
    position:relative;
    top:24px;
    left:0;
    width:212px;
    height:76px;
    cursor: pointer;
    // @media screen and (max-width: 1200px) {
    //   top:24px *0.828;
    //   width:212px *0.828;
    //   height:76px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   top:24px *0.64;
    //   width:212px *0.64;
    //   height:76px *0.64;
    // }

    @media screen and (max-width: 768px) {
      top:10px;
      left:10px;
      width:129px;
      height:47px;
    }

    img{
      width:212px;
      height: 76px;
      // @media screen and (max-width: 1200px) {
      //   width:212px *0.828;
      //   height:76px *0.828;
      // }
      //
      // @media screen and (max-width: 994px) {
      //   width:212px *0.64;
      //   height:76px *0.64;
      // }

      @media screen and (max-width: 768px) {
        width:129px;
        height:47px;
      }
    }

    z-index: 99;
  }

  .menu{
    position:relative;
    right:0;
    top:-76px;
    float: right;
    width:330px;
    height:66px;
    z-index: 99;
    // @media screen and (max-width: 1200px) {
    //   top:-76px *0.828;
    //   width:330px *0.828;
    //   height:66px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   top:-76px *0.64;
    //   width:330px *0.64;
    //   height:66px *0.64;
    // }
    //
    // @media screen and (max-width: 768px) {
    //
    // }

    ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;
      li{
        margin: 0;
        padding: 0;
        display: inline-block;
        cursor: pointer;

        img{
          // @media screen and (max-width: 1200px) {
          //   width:66px *0.828;
          //   height:66px *0.828;
          // }
          //
          // @media screen and (max-width: 994px) {
          //   width:66px *0.64;
          //   height:66px *0.64;
          // }
          //
          // @media screen and (max-width: 768px) {
          //
          // }
          width:66px;
          height:66px;
        }
      }
    }
  }
}

.vt-header-home{
  position: relative;
  // background-size: cover;
  padding: 0;
  // height:267px;
  z-index: 2;
  padding-bottom: 50px;

  .vt-logo{
    padding: 0;
  }

  .logo{
    position:relative;
    top:24px;
    left:0;
    width:212px;
    height:76px;
    cursor: pointer;
    // @media screen and (max-width: 1200px) {
    //   top:24px *0.828;
    //   width:212px *0.828;
    //   height:76px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   top:24px *0.64;
    //   width:212px *0.64;
    //   height:76px *0.64;
    // }

    @media screen and (max-width: 768px) {
      top:10px;
      left:10px;
      width:129px;
      height:47px;
    }

    img{
      width:212px;
      height: 76px;
      // @media screen and (max-width: 1200px) {
      //   width:212px *0.828;
      //   height:76px *0.828;
      // }
      //
      // @media screen and (max-width: 994px) {
      //   width:212px *0.64;
      //   height:76px *0.64;
      // }

      @media screen and (max-width: 768px) {
        width:129px;
        height:47px;
      }
    }

    z-index: 99;
  }

  .menu{
    position: absolute;
    right: 0;
    top: 0;
    float: right;
    width:330px;
    height:66px;
    z-index: 99;
    // @media screen and (max-width: 1200px) {
    //   // top:-76px *0.828;
    //   width:330px *0.828;
    //   height:66px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   // top:-76px *0.64;
    //   width:330px *0.64;
    //   height:66px *0.64;
    // }

    @media screen and (max-width: 768px) {

    }

    ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;
      li{
        margin: 0;
        padding: 0;
        display: inline-block;
        cursor: pointer;

        img{
          // @media screen and (max-width: 1200px) {
          //   width:66px *0.828;
          //   height:66px *0.828;
          // }
          //
          // @media screen and (max-width: 994px) {
          //   width:66px *0.64;
          //   height:66px *0.64;
          // }

          @media screen and (max-width: 768px) {

          }
          width:66px;
          height:66px;
        }
      }
    }
  }

  .carousel{
    margin-top:-76px;
    // @media screen and (max-width: 1200px) {
    //   margin-top:-76px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   margin-top:-76px *0.64;
    // }

    @media screen and (max-width: 768px) {

    }
  }
}

.menuButton{
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10001;

  #nav-icon {
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 0;
    span{
      position: relative;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;


    }

    .icon-bar {
      display: block;
      width: 30px;
      height: 2px;
      background-color: white;
      border-radius: 0;
    }

    span:nth-child(1) {
      top: 4px;
    }

    span:nth-child(2) {
      top: 12px;
    }

    span:nth-child(3) {
      top: 19px;
    }

  }

  #nav-icon.open span:nth-child(1){
    top: 15px;
    background-color: #313131;
    transform: rotate(135deg);
  }

  #nav-icon.open span:nth-child(2){
    opacity: 0;
  }

  #nav-icon.open span:nth-child(3){
    top: 11px;
    background-color: #313131;
    transform: rotate(-135deg);
  }

  #nav-icon.whiteBar span{
    background-color: white;
  }

  #nav-icon.blackBar span{
    background-color: #313131;
  }
}

.mobileMenu{
  position: absolute;
  top:0;
  left:0;
  background-color: #F7F7F7;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
  ul{
    list-style-type: none;
    display:block;
    padding: 0;
    li{
      padding: 15px 25px;
      a{
        font-family: $mjh;
        font-size: 22px;
        color:black;
        text-decoration: none;
      }
    }
    li.logo{
      width:129px;
      padding-bottom: 20px;
      img{
        width:129px;
      }
    }
    li.other{
      padding: 0;
      margin-top: 15px;
      cursor: pointer;
      ul{
        width:100%;
        display: inline-flex;
        padding: 0;
        li{
          width: 33%;
          display:inline-block;
          padding: 10px 20px;
          text-align: center;
          img{
            width:25px;
          }
        }
        li.white{
          background-color: white;
        }
        li.grey{
          background-color: #9B9B9B;
        }
        li.black{
          background-color: #4A4A4A;
          width: 34%;
        }
      }
    }
  }
}

.sectionTitle{
  padding: 0;
  position: relative;
  margin-top: -60px;
  top: -70px;
  text-align: center;
  font-family: $mjh;
  font-size: 42px;
  color:black;
  z-index: 3;
  @media screen and (max-width: 1200px) {
    margin-top: -50px;
    top: -70px *0.828;
    font-size: 42px *0.828;
  }

  @media screen and (max-width: 994px) {
    margin-top: -60px *0.64;
    top: -70px *0.64;
    font-size: 42px *0.64;
  }

  @media screen and (max-width: 768px) {
    text-align: left;
    font-size: 38px;
    margin-top: -54px;
    top: -10px;
    padding-left: 10px;
  }
}

.vt-section{
  position: relative;
  padding: 0;
  z-index: 2;


  .sectionCategory{
    position: relative;
    height: 48px;
    background-color: black;
    text-align: center;
    overflow: hidden;
    // @media screen and (max-width: 1200px) {
    //   height: 48px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   height: 48px *0.64;
    // }

    @media screen and (max-width: 768px) {
      height: auto;
      text-align: left;
    }

    .sectionCategoryContainer{
      overflow: hidden;
    }

    .sectionCategoryMore{
      position: relative;
      margin-top: -58px;
      float: right;
      color: #fff;
      width: 100px;
      height: 48px;
      padding: 15px;
      font-size: 16px;
      text-align: right;
      background: linear-gradient(to right, rgba(125,185,232,0) 0%,rgba(0,0,0,1) 22%);
      cursor: pointer;
      img{
        position: relative;
        height: 15px;
        padding-left: 10px;
        top: -1px;
      }
    }

    .sectionCategoryBack{
      display: none;
      position: relative;
      margin-top: -58px;
      float: left;
      color: #fff;
      width: 100px;
      height: 48px;
      padding: 15px;
      font-size: 16px;
      text-align: left;
      background: linear-gradient(to right, rgba(0,0,0,1) 78%, rgba(125,185,232,0) 100%);
      cursor: pointer;
      img{
        position: relative;
        height: 15px;
        padding-right: 10px;
        top: -1px;
      }
    }

    ul.sectionCategoryList{
      position: relative;
      list-style-type: none;
      display: inline-block;
      padding-left: 0;
      @media screen and (max-width: 768px) {
        padding: 0 15px;
        display: block;
      }

      .sectCat{
        @media screen and (max-width: 768px) {
          display: none;
          height: auto;
          padding: 0;
          padding-bottom: 15px;
        }
      }

      li{
        margin: 0;
        padding: 15px;
        display: inline-block;
        // width:120px;
        height: 48px;

        // @media screen and (max-width: 1200px) {
        //   padding: 11px ;
        //   width:120px *0.828;
        //   height: 48px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   padding: 6px;
        //   width:87px;
        //   height: 48px *0.64;
        // }

        @media screen and (max-width: 768px) {
          display: none;
          background-color:black;
          height: 48px;
          padding: 15px 0;
          width: 100%;
        }

        a{
          font-family: $mjh;
          font-size: 16px;
          color:white;
          text-decoration: none;
          // @media screen and (max-width: 1200px) {
          //   font-size: 16px *0.828;
          // }
          //
          // @media screen and (max-width: 994px) {
          //   font-size: 16px *0.64;
          // }

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        span{
          font-family: $mjh;
          font-size: 14px;
          color:#999;
          text-decoration: none;
          // @media screen and (max-width: 1200px) {
          //   font-size: 14px *0.828;
          // }
          //
          // @media screen and (max-width: 994px) {
          //   font-size: 14px *0.64;
          // }

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        .arrow{
          float:right;
          height: 15px;
          position: relative;
          top:5px;

        }
      }

      li.active{
        background-color: #D8D8D8;
        @media screen and (max-width: 768px) {
          background-color:black;
          display: block;
          height: 48px;
          padding: 15px 0;
          width: 100%;
        }

        a{
          font-weight: bold;
          color:black;
          @media screen and (max-width: 768px) {
            color: white;
            font-size: 16px;
          }
        }

      }

      li:hover{
        background-color: #D8D8D8;
        @media screen and (max-width: 768px){
          background-color:black;
        }
        a{
          color:black;
          @media screen and (max-width: 768px){
            color: white;
          }
        }
      }
    }

    ul.mobileSectionCategory{
      padding: 0;
      li.mobileItem{
        @media screen and (max-width: 768px) {
          display: block;
          background-color:black;
          height: 48px;
          padding: 15px 0;
          width: 100%;
        }
      }
    }
  }
}

.mobileSectionPic{
  position: relative;
  background-image: url(../images/img-blog-profile.jpg);
  background-position: center;
  background-size: cover;
  height:400px;
  width:100%;
  margin:20px 0;
  padding: 0;
  z-index: 2;
  @media screen and (max-width: 414px) {
    height:200px;
  }
  .filterBtn{
    position: absolute;
    bottom: 15px;
    left: 15px;
    width:65px;
    height:35px;
    background-color: #C9C9C9;
    border-radius: 5px;
    cursor: pointer;
    a{
      position: relative;
      padding: 0 15px;
      top: 7px;
      letter-spacing: 1px;
      font-family: $mjh;
      font-size: 16px;
      color:black;
      text-decoration: none;
    }
  }

}

.vt-main{
  padding: 0;
  background-color: white;
}

.vt-footer{
    background-color: #F0F0F0;
    padding: 0;
    height:220px;
    padding-top: 35px;

    @media screen and (max-width: 1200px) {
      padding-top: 35px *0.828;
      height:220px *0.828;
    }

    @media screen and (max-width: 994px) {
      padding-top: 35px *0.64;
      height:160px;
    }

    @media screen and (max-width: 768px) {
      height: auto;
    }

    .footerTop{
      // width:87.5%;
      margin: auto;
      .logo{
        position:relative;
        width:129px;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
          width:129px *0.828;
        }

        @media screen and (max-width: 994px) {
          width:129px *0.64;
        }

        @media screen and (max-width: 768px) {
          width:129px;
          padding-bottom: 10px;
        }

        img{
          width:129px;

          @media screen and (max-width: 1200px) {
            width:129px *0.828;
          }

          @media screen and (max-width: 994px) {
            width:129px *0.64;
          }

          @media screen and (max-width: 768px) {
            width:129px;
          }
        }
      }

      .footerList{
        position: relative;
        top: -20px;
        text-align: right;
        @media screen and (max-width: 1200px) {
          top: -20px *0.828;
        }

        @media screen and (max-width: 994px) {
          top: -20px *0.64;
        }

        @media screen and (max-width: 768px) {
          text-align: left;
          top:0;
        }
        ul{
          list-style-type: none;
          display:block;
          margin: 0;
          padding: 0;
          li{
            display:inline-block;
            padding-left: 25px;
            @media screen and (max-width: 1200px) {
              padding-left: 25px *0.828;
            }

            @media screen and (max-width: 994px) {
              padding-left: 25px *0.64;
            }

            @media screen and (max-width: 768px) {
              display:block;
              padding-left: 0;
              padding-bottom: 5px;
            }
            a{
              text-decoration: none;
              font-family: $mjh;
              font-size:13px;
              color:black;
              @media screen and (max-width: 1200px) {
                font-size:13px *0.828;
              }

              @media screen and (max-width: 994px) {
                font-size:13px *0.64;
              }

              @media screen and (max-width: 768px) {
                font-size:15px;
              }
            }
          }
        }
      }
    }

    .footerMiddle{
      // width:87.5%;
      margin: auto;
      .copyright{
        position: relative;
        text-decoration: none;
        font-family: $mjh;
        font-size:11px;
        color:black;
        @media screen and (max-width: 1200px) {
          font-size:11px *0.828;
        }

        @media screen and (max-width: 994px) {
          font-size:11px *0.64;
        }

        @media screen and (max-width: 768px) {
          font-size: 11px;
          padding-bottom: 5px;
        }
      }
      .footerList{
        position: relative;
        text-align: right;
        top:-18px;
        @media screen and (max-width: 1200px) {
          top: -18px *0.828;
        }

        @media screen and (max-width: 994px) {
          top: -18px *0.64;
        }

        @media screen and (max-width: 768px) {
          text-align: left;
          top:0;
          padding-bottom: 15px;
        }
        ul{
          list-style-type: none;
          display:block;
          margin: 0;
          padding: 0;
          li{
            display:inline-block;
            font-family: $mjh;
            font-size:11px;
            color:black;
            padding-left: 5px;
            @media screen and (max-width: 1200px) {
              font-size:11px *0.828;
            }

            @media screen and (max-width: 994px) {
              font-size:11px *0.64;
            }

            @media screen and (max-width: 768px) {
              font-size:11px;
              padding-left: 0;
            }
            a{
              text-decoration: none;
              font-family: $mjh;
              font-size:11px;
              color:black;
              @media screen and (max-width: 1200px) {
                font-size:11px *0.828;
              }

              @media screen and (max-width: 994px) {
                font-size:11px *0.64;
              }

              @media screen and (max-width: 768px) {
                font-size:11px;
              }
            }
          }
        }
      }
    }

    .footerSeparator{
      // width: 87.5%;
      height:1px;
      background-color: #6D6D6D;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 15px;
      @media screen and (max-width: 1200px) {
        margin-top: 15px *0.828;
        margin-bottom: 15px *0.828;
      }

      @media screen and (max-width: 994px) {
        margin-top: 15px *0.828;
        margin-bottom: 15px *0.828;
      }

      @media screen and (max-width: 768px) {

      }
    }

    .footerBottom{
        // width:87.5%;
        margin: auto;
        text-align: right;
        color: #6D6D6D;
        font-family: $mjh;
        font-size:11px;
        @media screen and (max-width: 1200px) {
          font-size:11px *0.828;
        }

        @media screen and (max-width: 994px) {
          font-size:11px *0.64;
        }

        @media screen and (max-width: 768px) {
          font-size:11px;
          text-align: left;
          padding-bottom: 15px;
        }
    }
}

.vt-path{
  padding: 0;
  background-color: white;
  .inner{
    // width:87.5%;
    padding: 35px 0px;
    font-family: $mjh;
    font-size:14px;
    a{
      text-decoration: none;
      color:black;
    }
  }
}

.vt-row{
  margin: 0;
}

.vt-col{
  padding: 0;
}

.vt-popup{
  position:absolute;
  top:66px;
  right: 0;
  width:330px;
  z-index: 99;
  display: none;

  // @media screen and (max-width: 1200px) {
  //   top:66px *0.828;
  //   width:330px *0.828;
  // }
  //
  // @media screen and (max-width: 994px) {
  //   top:66px *0.64;
  //   width:330px *0.64;
  // }

  @media screen and (max-width: 768px) {

  }
  ul{
    list-style-type: none;
    display:block;
    background-color: #F7F7F7;
    padding-left: 10px;
    li{
      padding: 15px;
      a{
        font-family: $mjh;
        font-size: 22px;
        color:black;
        text-decoration: none;
        // @media screen and (max-width: 1200px) {
        //   font-size: 22px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   font-size: 22px *0.64;
        // }

        @media screen and (max-width: 768px) {

        }
      }

    }
  }
}

.vt-login{
  position: absolute;
  top:66px;
  right:264px;
  width:335px;
  height: 345px;
  background-color: rgba(200,200,200,0.9);
  display: none;
  z-index: 99;

  // @media screen and (max-width: 1200px) {
  //   top:66px *0.828;
  //   right:264px *0.828;
  //   width:335px *0.828;
  //   height: 345px *0.828;
  // }
  //
  // @media screen and (max-width: 994px) {
  //   top:66px *0.64;
  //   right:264px *0.64;
  //   width:335px *0.64;
  //   height: 345px *0.64;
  // }

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 66px;
    right: 0px;
    width: 100%;
    background-color: #f7f7f7;
    z-index: 10001;
  }

  ul{
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 15px 30px;
    @media screen and (max-width: 1200px) {
      padding: 15px *0.828 30px *0.828;
    }

    @media screen and (max-width: 994px) {
      padding: 15px *0.828 30px *0.64;
    }

    @media screen and (max-width: 768px) {
      padding: 15px 25px;
    }

    li{
      font-family: $mjh;
      font-size:14px;
      color:black;
      text-align: left;
      padding-bottom: 10px;
      // @media screen and (max-width: 1200px) {
      //   font-size:14px *0.828;
      //   padding-bottom: 10px *0.828;
      // }
      //
      // @media screen and (max-width: 994px) {
      //   font-size:14px *0.64;
      //   padding-bottom: 10px *0.64;
      // }

      @media screen and (max-width: 768px) {
        color: #9B9B9B;
      }
      input{
        width:268px;
        height:42px;
        border:none;
        border-radius: 4px;
        outline: none;
        // @media screen and (max-width: 1200px) {
        //   width:268px *0.828;
        //   height:42px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   width:268px *0.64;
        //   height:42px *0.64;
        // }

        @media screen and (max-width: 768px) {
          width:100%;
        }
      }

      a{
        font-size:13px;
        color:black;
        text-align:center;
        text-decoration: underline;
        // @media screen and (max-width: 1200px) {
        //   font-size:13px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   font-size:13px *0.64;
        // }

        @media screen and (max-width: 768px) {

        }
      }

      .loginBtn{
        width: 120px;
        height: 40px;
        border: 2px solid #000;
        text-align: center;
        padding: 7px;
        font-family: $mjh;
        font-size:16px;
        color: black;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        // @media screen and (max-width: 1200px) {
        //   width: 120px *0.828;
        //   height: 40px *0.828;
        //   padding: 7px *0.828;
        //   font-size:16px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   width: 120px *0.64;
        //   height: 40px *0.64;
        //   padding: 7px *0.64;
        //   font-size:16px *0.64;
        // }

        @media screen and (max-width: 768px) {
          width: 120px;
          height: 40px;
          text-align: center;
          padding: 7px;
          font-size:16px;
        }
      }
      .loginBtn:hover{
        background-color: black;
        color:white;
      }
    }
    li.center{
      text-align: center;
    }
  }
  .signupBtn{
    height: 40px;
    font-size: 16px;
    color: white;
    text-align: center;
    background-color: #6D6D6D;
    padding: 10px 15px;
    // @media screen and (max-width: 1200px) {
    //   font-size:14px;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   font-size:13px;
    // }

    @media screen and (max-width: 768px) {
      width: 120px;
      height: 40px;
      border: 2px solid #404040;
      background-color: #404040;
      text-align: center;
      padding: 7px;
      font-family: $mjh;
      font-size:16px;
      color: white;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .signupBtn:hover{
    background-color: black;
  }
}

.vt-search{
  position: absolute;
  width:432px;
  height: 55px;
  top:66px;
  right:132px;
  background-color: #ccc;
  display: none;
  z-index: 99;
  // @media screen and (max-width: 1200px) {
  //   top:66px *0.828;
  //   right:132px *0.828;
  //   width:432px *0.828;
  //   height: 55px *0.828;
  // }
  //
  // @media screen and (max-width: 994px) {
  //   top:66px *0.64;
  //   right:132px *0.64;
  //   width:432px *0.64;
  //   height: 55px *0.64;
  // }

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 100%;
    top: 66px;
    right: 0;
    z-index: 10001;
    background-color: #f7f7f7;
  }

  ul{
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 15px;
    @media screen and (max-width: 1200px) {
      padding: 15px *0.828;
    }

    @media screen and (max-width: 994px) {
      padding: 15px *0.64;
    }

    @media screen and (max-width: 768px) {
      padding: 15px 30px;
    }
    li{
      display: inline-block;
      font-family:$mjh;
      font-size:15px;
      color:black;
      // @media screen and (max-width: 1200px) {
      //   font-size:15px *0.828;
      // }
      //
      // @media screen and (max-width: 994px) {
      //   font-size:15px *0.864;
      // }

      @media screen and (max-width: 768px) {
        font-size:16px;
        display: block;
      }

      input{
        width:355px;
        height: 25px;
        background-color: transparent;
        border:none;
        border-bottom: 1px solid black;
        outline: none;

        // @media screen and (max-width: 1200px) {
        //   width:355px *0.828;
        //   height: 25px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   width:355px *0.64;
        //   height: 25px *0.64;
        // }

        @media screen and (max-width: 768px) {
          width: 100%;
          height:42px;
        }
      }

      .searchBtn{
        width: 40px;
        height: 25px;
        border: 1px solid #000;
        text-align: center;
        padding: 3px;
        font-family: $mjh;
        font-size:12px;
        color: black;
        margin: auto;
        cursor: pointer;
        // @media screen and (max-width: 1200px) {
        //   width: 40px *0.828;
        //   height: 25px *0.828;
        //   padding: 3px *0.828;
        //   font-size:12px *0.828;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   width: 40px *0.64;
        //   height: 25px *0.64;
        //   padding: 3px *0.64;
        //   font-size:12px *0.64;
        //   position: relative;
        //   top:-2px;
        // }

        @media screen and (max-width: 768px) {
          width: 120px;
          height: 40px;
          border: 2px solid #000;
          text-align: center;
          padding: 7px;
          font-family: $mjh;
          font-size:16px;
          color: black;
          margin: auto;
          margin-top: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }

      .searchBtn:hover{
        background-color: black;
        color: white;
      }
    }
  }
}

.vt-lang{
  position: absolute;
  width:66px;
  top:66px;
  right:198px;
  background-color: #ccc;
  display: none;
  z-index: 99;
  // @media screen and (max-width: 1200px) {
  //   top:66px *0.828;
  //   right:198px *0.828;
  //   width:66px *0.828;
  // }
  //
  // @media screen and (max-width: 994px) {
  //   top:66px *0.64;
  //   right:198px *0.64;
  //   width:66px *0.64;
  // }

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 100%;
    top: 66px;
    right: 0;
    z-index: 10001;
    background-color: #f7f7f7;
  }

  ul{
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 0 15px;
    // @media screen and (max-width: 1200px) {
    //   padding: 0 15px *0.828;
    // }
    //
    // @media screen and (max-width: 994px) {
    //   padding: 0 15px *0.64;
    // }

    @media screen and (max-width: 768px) {
      padding: 15px 30px;
    }
    li{
      display: block;
      padding-bottom: 15px;
      padding-top: 15px;
      // @media screen and (max-width: 1200px) {
      //   padding-bottom: 15px *0.828;
      //   padding-top: 15px *0.828;
      // }
      //
      // @media screen and (max-width: 994px) {
      //   padding-bottom: 15px *0.64;
      //   padding-top: 15px *0.64;
      // }

      @media screen and (max-width: 768px) {

      }
      text-align: center;
      a{
        font-family:$mjh;
        font-size:12px;
        color:black;
        text-decoration: none;
        // @media screen and (max-width: 1200px) {
        //   font-size:9px;
        // }
        //
        // @media screen and (max-width: 994px) {
        //   font-size:7px;
        // }

        @media screen and (max-width: 768px) {
          font-size:16px;
        }
      }
    }

    li.sep{
      height:1px;
      text-align: center;
      width:90%;
      background-color: #6D6D6D;
      padding: 0;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
